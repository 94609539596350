import React from "react";
import { PAGE_OVERVIEW } from "../routes";

export type TabContextType = {
    active: string;
    setActive: (active: string) => void;
}

export const TabContext = React.createContext<TabContextType>({
    active: PAGE_OVERVIEW,
    setActive: () => {
    }
});

export const useTabContext = () => React.useContext(TabContext);
