import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from '../translations/de.json';
import en from '../translations/en.json';
import it from '../translations/it.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            de,
            en,
            it
        },
        lng: "de",
        fallbackLng: "de",

        interpolation: {
            escapeValue: false
        }
    });
