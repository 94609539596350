import { createTheme } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles/createTheme";

export const theme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#167F92',
            light: '#1C9FB6',
            dark: '#157b8d',
        },
        secondary: {
            main: '#7701FF',
            light: '#8419ff',
            dark: '#6600e1',
        },
        error: {
            main: '#F44336'
        },
        info: {
            main: '#2196F3'
        },
        success: {
            main: '#4CAF50'
        },
        background: {
            default: '#ffffff',
            dark: 'rgba(217, 217, 217, 54)',
            accent: 'rgba(28,159,182, 0.1)',
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)"
        },
        brand: {
            mint: "#1C9FB6",
        },
        chart: {
            default: "#E0E0E0",
            default_dark: "#9E9E9E",
            primary: "#7701FF",
            primaryLight: "rgba(119,1,255,0.49)",
            primaryDark: "rgb(65,0,136)",
            secondary: "#00C3AA",
            secondaryLight: "rgba(0,195,170,0.5)",
            secondaryDark: "rgb(0,117,102)",
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },

    typography: {
        allVariants: {},
        h1: {},
        h2: {},
        h3: {},
        h4: {
            fontSize: '40px'
        },
        h6: {
            fontWeight: '300'
        },
        button: {},
        body1: {
            fontWeight: '300'
        },
        body2: {
            fontWeight: '300'
        },
        subtitle1: {
            fontWeight: '300'
        },
        subtitle2: {
            fontWeight: '300'
        },
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {}
            }
        },
        MuiIcon: {
            styleOverrides: {
                root: {},
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {},
            },

            variants: [
                {
                    props: { size: 'large' },
                    style: {
                        paddingTop: `calc(var(--theme-spacing) * 1.5)`,
                        paddingBottom: `calc(var(--theme-spacing) * 1.5)`
                    }
                }
            ]
        },
        MuiCard: {
            defaultProps: {
                elevation: 4,
            }
        },
        MuiAccordion: {
            defaultProps: {
                elevation: 4,
                variant: 'elevation',
            },
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                }
            }
        }
    }
}

declare module '@mui/material/styles' {
    interface TypeBackground {
        dark: string;
        accent: string;
    }

    interface PaletteOptions {
        brand: {
            mint: string;
        },
        chart: {
            default: string;
            default_dark: string;
            primary: string;
            primaryLight: string;
            primaryDark: string;
            secondary: string;
            secondaryLight: string;
            secondaryDark: string;
        }
    }

    interface Palette extends PaletteOptions {
    }
}

export const getTheme = () => createTheme(theme);
