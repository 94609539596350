import { useTheme } from "@mui/material";
import { MuiCssVars } from "../components/css-vars";


export const CssVars = () => {
    const theme = useTheme();
    return <>
        <MuiCssVars rules={ {
            '--theme-palette-chart-primary-light': theme.palette.chart.primaryLight,
            '--theme-palette-chart-secondary-light': theme.palette.chart.secondaryLight,
            '--theme-palette-background-dark': theme.palette.background.dark,
            '--theme-palette-background-accent': theme.palette.background.accent,
        } }/>
    </>
}

export default CssVars;
