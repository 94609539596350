import { PropsWithChildren } from "react";
import { useRouter } from "next/router";
import { CircularProgress } from "@mui/material";
import { useSession } from "next-auth/react";
import { useTimeout } from "react-use";

export const AuthWrapper = ({
                                requiresAuth,
                                children,
                                isAuthenticated
                            }: PropsWithChildren<{ requiresAuth?: boolean, isAuthenticated?: boolean }>) => {
    const session = useSession();
    const router = useRouter();
    const [ isReady ] = useTimeout(200);

    if (!isReady || requiresAuth && session.status === 'loading') {
        return <div>
            <CircularProgress/>
        </div>;
    }

    if (requiresAuth && (session.status === 'unauthenticated' || isAuthenticated === false)) {
        router.replace('/auth/login');
        return null;
    }

    return <>{ children }</>
}
