/**
 * Log pageview
 */
export const pageview = (url: string) => {
    if (!global.gtag) {
        return;
    }
    global.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
    })
}

/**
 * Log specific event
 */
export const event = ({ action, params }: { action: string, params: unknown }) => {
    if (!global.gtag) {
        return;
    }
    global.gtag('event', action, params)
}
