import { GlobalStyles, PaletteColor, useTheme } from "@mui/material";

const generateColorVariables = (key: string, color: PaletteColor) => {
    return {
        [`--theme-palette-${ key }-main`]: color.main,
        [`--theme-palette-${ key }-light`]: color.light,
        [`--theme-palette-${ key }-contrast-text`]: color.contrastText,
        [`--theme-palette-${ key }-dark`]: color.dark,
    }
}

export type MuiCssVarsProps = {
    /**
     * Custom CSS variables and rules
     */
    rules?: Record<string, string>;
};

export function MuiCssVars({ rules = {} }: MuiCssVarsProps) {
    const theme = useTheme();

    return <GlobalStyles styles={ {
        body: {
            // Sizes
            '--theme-spacing': theme.spacing(1),

            // Shadows
            '--theme-shadows-1': theme.shadows[1],
            '--theme-shadows-2': theme.shadows[2],
            '--theme-shadows-3': theme.shadows[3],
            '--theme-shadows-4': theme.shadows[4],
            '--theme-shadows-5': theme.shadows[5],
            '--theme-shadows-6': theme.shadows[6],
            '--theme-shadows-7': theme.shadows[7],
            '--theme-shadows-8': theme.shadows[8],
            '--theme-shadows-9': theme.shadows[9],
            '--theme-shadows-10': theme.shadows[10],
            '--theme-shadows-11': theme.shadows[11],
            '--theme-shadows-12': theme.shadows[12],
            '--theme-shadows-13': theme.shadows[13],
            '--theme-shadows-14': theme.shadows[14],
            '--theme-shadows-15': theme.shadows[15],
            '--theme-shadows-16': theme.shadows[16],
            '--theme-shadows-17': theme.shadows[17],
            '--theme-shadows-18': theme.shadows[18],
            '--theme-shadows-19': theme.shadows[19],
            '--theme-shadows-20': theme.shadows[20],
            '--theme-shadows-21': theme.shadows[21],
            '--theme-shadows-22': theme.shadows[22],
            '--theme-shadows-23': theme.shadows[23],

            // Z-Index
            '--theme-zIndex-tooltip': theme.zIndex.tooltip,
            '--theme-zIndex-appbar': theme.zIndex.appBar,
            '--theme-zIndex-mobile-stepper': theme.zIndex.mobileStepper,
            '--theme-zIndex-modal': theme.zIndex.modal,
            '--theme-zIndex-drawer': theme.zIndex.drawer,
            '--theme-zIndex-snackbar': theme.zIndex.snackbar,
            '--theme-zIndex-speed-dial': theme.zIndex.speedDial,

            // Palette
            ...generateColorVariables('primary', theme.palette.primary),
            ...generateColorVariables('secondary', theme.palette.secondary),
            ...generateColorVariables('success', theme.palette.success),
            ...generateColorVariables('error', theme.palette.error),
            ...generateColorVariables('warning', theme.palette.warning),
            ...generateColorVariables('info', theme.palette.info),

            '--theme-palette-text-primary': theme.palette.text.primary,
            '--theme-palette-text-secondary': theme.palette.text.secondary,
            '--theme-palette-text-disabled': theme.palette.text.disabled,


            '--theme-palette-common-black': theme.palette.common.black,
            '--theme-palette-common-white': theme.palette.common.white,

            '--theme-palette-divider': theme.palette.divider,

            '--theme-palette-background-paper': theme.palette.background.paper,
            '--theme-palette-background-default': theme.palette.background.default,

            ...rules,
        },
    } }/>;
}
