import React from 'react';

export type PageBackgroundContextVue = {
    backgroundColor?: string | null;
    setColor: (value: string | null) => void;
}

const value: PageBackgroundContextVue = {
    backgroundColor: null,
    setColor: () => {
    }
}
export const PageBackgroundContext = React.createContext<PageBackgroundContextVue>(value)

export const usePageBackgroundContext = () => React.useContext(PageBackgroundContext);
