import { AppProps } from "next/app";
import { getTheme } from "../src/theme/theme";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { GlobalStyles, StyledEngineProvider, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import '@dreipol/t3-ui/lib/bundle.css'
import '../src/theme/globals.css'
import '../src/i18n';
import CssVars from "../src/theme/css-vars";
import { useEffect, useMemo, useState } from "react";
import { TabContext, TabContextType } from "../src/context/tab-context";
import { useRouter } from "next/router";
import { PAGE_OVERVIEW } from "../src/routes";

import { SessionProvider } from "next-auth/react"
import { AuthWrapper } from "../src/components/auth-wrapper";
import { PageBackgroundContext } from "../src/components/context/page-background-context";
import i18next from "i18next";
import { pageview } from "../src/utils/ga";
import { Session } from "next-auth";
import createEmotionCache from "../src/create-emotion-cache";
import { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

type Props = AppProps<{
    session: Session;
    isAuthenticated?: boolean;
    requiresAuth?: boolean;
    emotionCache: EmotionCache
}>;

const clientSideEmotionCache = createEmotionCache();


export default function App({ Component, pageProps: { session, emotionCache, ...pageProps } }: Props) {
    const router = useRouter();
    const [ activeTab, setActiveTab ] = useState(PAGE_OVERVIEW);
    const [ backgroundColor, setBackgroundColor ] = useState<string | null>(null);

    i18next.changeLanguage(router.locale);

    useEffect(() => {
        const handleRouteChange = (url) => {
            pageview(url)
        }
        //When the component is mounted, subscribe to router changes
        //and log those page views
        router.events.on('routeChangeComplete', handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [ router.events ])


    const tabContextValue: TabContextType = useMemo(() => {
        return {
            active: activeTab,
            setActive: (tab) => {
                router.push(
                    {
                        pathname: tab,
                    },
                    tab,
                    { shallow: false }
                );
                setActiveTab(tab)
            },
        }
    }, [ activeTab, setActiveTab ]);

    useEffect(() => {
        if (router.isReady) {
            setActiveTab(router.asPath);
        }
    }, [ router ]);

    const pageBackgroundContextValue = {
        backgroundColor,
        setColor: setBackgroundColor
    }

    useEffect(() => {
        if (backgroundColor) {
            document.body.style.setProperty('--global-background-color', backgroundColor);
        } else {
            document.body.style.removeProperty('--global-background-color');
        }
    }, [ backgroundColor ]);

    return <>
        <SessionProvider session={ session }>
            <CacheProvider value={ emotionCache }>
                <ThemeProvider theme={ getTheme() }>
                    <LocalizationProvider dateAdapter={ AdapterDateFns }>
                        <StyledEngineProvider injectFirst>
                            <GlobalStyles styles={{body: {opacity: 0}}}/>
                            <CssBaseline/>
                            <CssVars/>
                            <PageBackgroundContext.Provider value={ pageBackgroundContextValue }>
                                <TabContext.Provider value={ tabContextValue }>
                                    <AuthWrapper
                                        isAuthenticated={ pageProps.isAuthenticated }
                                        requiresAuth={ pageProps.requiresAuth }>
                                        <Component { ...pageProps } />
                                    </AuthWrapper>
                                </TabContext.Provider>
                            </PageBackgroundContext.Provider>
                        </StyledEngineProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </CacheProvider>
        </SessionProvider>
    </>
}
